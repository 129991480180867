import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import Testimonial from "~sections/customer-stories/Testimonial"
import Hero from "~sections/project/AiAssist/Hero/Hero"
import ProfileInfo from "~sections/project/AiAssist/Box-1/ProfileInfo"
import Conversation from "~sections/project/AiAssist/Box-2/Conversation"
import MessageGen from "~sections/project/AiAssist/Box-3/MessageGen"
import Benefits from "~sections/project/AiAssist/Benefits/benefits"
import { Helmet } from "react-helmet"

const header = {
    headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
    containerFluid:false,
    buttonBlock: (
      <HeaderButton
        className="ms-auto d-none d-xs-inline-flex"
        btnOneText="Login"
        btnTwoText="14 day free trial"
        mr="15px"
        mrLG="0"
      />
    ),
  }

  export default function AiAssist() {
    return (
      <>
      <Helmet>
        <title>Try AI Assist Today</title>
        <meta name="description" content="AI Assist revolutionizes how you prospect, making it easy to learn about a lead and send them a personalized outreach message. Improve your conversion here."/>
        <meta property="og:title" content="We-Connect | Try AI Assist Today"/>
        <meta property="og:description" content="AI Assist revolutionizes how you prospect, making it easy to learn about a lead and send them a personalized outreach message. Improve your conversion here. "/>
        <meta property="og:image" content="https://we-connect.io/we-connect_ai-digest-page.png"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://we-connect.io/ai-digest"/>
        <meta property="twitter:card" content="summary_large_image"/><meta property="twitter:site" content="@WeConnect_io"/>
        <meta property="twitter:title" content="We-Connect | Try AI Assist Today"/>
        <meta property="twitter:description" content="AI Assist revolutionizes how you prospect, making it easy to learn about a lead and send them a personalized outreach message. Improve your conversion here. "/>
        <meta property="twitter:image" content="https://we-connect.io/we-connect_ai-digest-page.png"/>
    </Helmet>
      <PageWrapper headerConfig={header}>
        <Hero />
        <ProfileInfo />
        <Conversation />
        <MessageGen />
        <Benefits />
        <Testimonial />
        <CtaSection />
        <FooterOne />
      </PageWrapper>
      </>
    )
  }