import React from 'react'
//import { StaticImage as  Img } from "gatsby-plugin-image";
import Hero from './style'
import { Col, Container, Row } from 'react-bootstrap';
import imageOne from "~assets/image/media/AiAssist/svg/Hero-AI-Digest.svg";
//import { SuperTag } from '~components';
export default function HeroSection(){
  return(
<Hero className="position-relative bg-default">
  <Container>
  <Row>
      <Col className="col-xl-5 col-lg-5 col-md-8 col-xs-11 order-2 order-lg-1"
              xs="12">
        <Hero.ContentBlock mb="35px">
        <Hero.Title as="h1">
        AI Assist - Revolutionizing LinkedIn Outreach for Smarter Prospecting!<br className="d-none d-xs-none d-lg-none d-xl-block" />
        </Hero.Title>          
        
                <Hero.Text>
                AI Assist is a powerful tool designed to enhance your LinkedIn prospecting experience in three unique ways.
                 
                </Hero.Text>
        </Hero.ContentBlock>
        <Hero.Button  className="btn btn-primary text-white" target="_blank" to="//account.we-connect.io/signup" >Start 14 day free trial</Hero.Button>
      </Col>
      <Col xs="14" className="col-xl-7 col-lg-7 col-md-8 col-xs-11 order-1 order-lg-2">
        <Hero.Image>
        <img className="w-100" src={imageOne} alt="content" layout="fullWidth" placeholder="blurred" />
        </Hero.Image>
      </Col>
    </Row>
  </Container>
  
</Hero>
)
}