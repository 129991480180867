import styled from 'styled-components/macro';
import { Box, Heading,Button,Paragraph } from '~styled';

const Content = styled(Box)`
    padding-top: 60px;
    padding-bottom: 60px;
    @media (min-width:768px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @media (min-width:992px) {
        padding-top: 125px;
        padding-bottom: 130px;
    }
`
Content.TextBlock=styled(Box)`

`
Content.Block = styled(Box)`
padding-top: 80px;
@media (min-width: 768px) {
    padding-top: 0;
}

`
Content.Title = styled(Heading)`
font-size: 34px;
font-weight: 500;
text-align: center;
letter-spacing: -2px;
line-height: 1.20689;
margin-bottom: 32px;

@media (min-width: 480px) {
    font-size: 38px;
}

@media (min-width: 768px) {
    font-size: 48px;
}

@media (min-width: 992px){
    font-size: 34px;
}

`

Content.Box = styled(Box)`

`

export default Content;