import React from 'react'
//import { StaticImage as Img } from 'gatsby-plugin-image';
import Content from './style'
import { SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import image from "~assets/image/media/AiAssist/svg/Thread-Digest.svg";

export default function Conversation({}){

return(
<Content>
  
  <Container>
    <Content.Inner>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
      <Col className="col-xl-5 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-1">
        <Content.TextBlock>
          <Content.Title as="h2"> <SuperTag value="Simplify Conversation Threads"/> </Content.Title>

          <Content.Text> <SuperTag value="It can be overwhelming to keep track of conversation threads during a messaging campaign. Using AI Assist, you can simplify this process by breaking down the essential points of a conversation in an easy-to-read summary. By doing so, you’ll be able to quickly understand the context of past conversations and identify potential opportunities for engagement."/> </Content.Text>
          <Content.Button target="_blank" to="//account.we-connect.io/signup">Get Started for Free</Content.Button>
        </Content.TextBlock>
      </Col>
      <Col xs="12" className="col-xl-7 col-lg-6 col-md-8 col-xs-10 order-1 order-lg-2">
        <Content.Image> 
        <img height="435" className="w-100" src={image} alt="content" layout="fullWidth" placeholder="blurred" />
        </Content.Image>
      </Col>
    </Row>
    </Content.Inner>
  </Container>
</Content>


)
}